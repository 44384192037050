/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'Spotify';
    font-weight: lighter;
    src: url('/assets/fonts/spotify-light.ttf');
  }

  @font-face {
    font-family: 'Spotify';
    font-weight: bold;
    src: url('/assets/fonts/spotify-bold.ttf');
  }



@font-face {
    font-family: 'Steiner';
    font-weight: lighter;
    src: url('/assets/fonts/SteinerLight.ttf');
  }


.toast_style{
    --background: #ffd800;
    --color: #404040;
    text-align: center;
    transform: translateY(-60px)!important;
}

.custom-popover{
    --max-height: 340px;
    --max-width: 200px;
    --backdrop-opacity: 0.5;
    --overflow: hidden;
    --align: left;

    &::part(backdrop){
        --background-color: white;
    }

    &::part(arrow){
        --background: red;
    }

    &::part(content){
        --font-family: "Spotify";
        --overflow: hidden;
    }


}

.ios .custom-popover{
    --max-height: 400px;
    --max-width: 200px;
    --backdrop-opacity: 0.5;
    --overflow: hidden;
    --align: left;

    &::part(backdrop){
        --background-color: white;
    }

    &::part(arrow){
        --background: red;
    }

    &::part(content){
        --font-family: "Spotify";
        --overflow: hidden;
    }


}



.small-modal{
    --backdrop-opacity: 0.5;
    --max-width: 600px;
    --modal-wrapper{
        background: transparent !important;
    }
    &::part(backdrop){
        --background-color: transparent;
        
    }

    &::part(arrow){
        --background: red;
    }

    &::part(content){
        --font-family: "Spotify";
        width: 80%;
        height: 70%;
        margin: auto;
        border-radius: 20px;
        background-color: transparent;
    }


}

.no-scroll{
    --overflow: hidden;
}

.my-custom-class {
    --background: #e5e5e5;
    --width: 500px;
    font-family: "Spotify";
  }